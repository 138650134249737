import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import '../stylesheets/App.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      marginTop: 'calc(100% - 60px) !important',
      bottom: 0,
      background: "black",
      position: 'sticky'
    },
    map: {
      border:0,
      [theme.breakpoints.between(0,400)]: {
        width: '300px',
        height: '300px',
      },
      [theme.breakpoints.between(401,500)]: {
        width: '375px',
        height: '300px',
      },
      [theme.breakpoints.between(501,600)]: {
        width: '475px',
        height: '300px',
      },
      [theme.breakpoints.between(601,700)]: {
        width: '575px',
        height: '400px',
      },
      [theme.breakpoints.up(701)]: {
        width: '575px',
        height: '400px',
      },
      [theme.breakpoints.up(960)]: {
        width: '400px',
        height: '400px',
      },
      [theme.breakpoints.between(1051,1100)]: {
        width: '400px',
        height: '400px',
      },
      [theme.breakpoints.between(1101,1200)]: {
        width: '425px',
        height: '400px',
      },
      [theme.breakpoints.between(1201,1400)]: {
        width: '450px',
        height: '400px',
      },
      [theme.breakpoints.between(1401,1550)]: {
        width: '500px',
        height: '400px',
      },
      [theme.breakpoints.up(1551)]: {
        width: '600px',
        height: '400px',
      }
    }
}));

export default function RouteMap() {
  const classes = useStyles();

  return (
    <div>
        <iframe
            title="RouteMap"
            className={classes.map}
            loading="lazy" 
            allowFullScreen={true} 
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ81jFPjDDxUcRchQO6MLdcNY&key=AIzaSyD4Va6ckmc3J3UVOM6o9YoGSFx6oVy6VVE"
        />
    </div>
  );
}
