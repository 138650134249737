import { CssBaseline, Typography, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../../stylesheets/App.css";
import McKenzieMethodeSpecialisatieFoto from "../../images/mckenzie-methode.jpg";
import Copyright from "../../view/Copyright";
import NavigationBar from "../NavigationBar";
import TopBar from "../TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: "#4b0340",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
    font: {
      padding: 0,
      color: "#3d072d",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
    boodschap: {
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        marginLeft: "2rem",
        marginRight: "2rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        marginLeft: "4rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginLeft: "6rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        marginLeft: "6rem",
        textAlign: "left",
      },
    },
    imageGrid: {
      alignItems: "center",
      order: 2,
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        marginBottom: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
    },
    infoGrid: {
      marginTop: "1rem",
      order: 1,
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "2rem",
      },
    },
  })
);

function McKenzieMethode() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important", marginBottom: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BOOST Fysiotherapie / Fysiotherapie</title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio, manuele en orofaciale therapie in Nieuw Vennep. De praktijkeigenaar is Emilie Wegman, Manueel therapeut - OMT Orthopedische Manuele Therapie"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Orofaciale fysiotherapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/specialisaties/mckenzie-methode" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          alignItems="flex-start"
          className={classes.infoGrid}
        >
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{
              fontWeight: 700,
              fontSize: 24,
              color: "#4b0340",
              marginBottom: "1rem",
            }}
          >
            McKenzie Methode
          </Typography>
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            De McKenzie Methode® ook bekend als Mechanische Diagnose en
            Therapie® (MDT) is een internationaal geprezen methode voor
            onderzoek en behandeling van pijn aan de wervelkolom en ledematen.
            Het is ontwikkeld door fysiotherapeut/kinesitherapeut Robin McKenzie
            uit Nieuw Zeeland. Deze methode wordt al meer dan 30 jaar wereldwijd
            gebruikt.
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            MDT therapeuten zijn opgeleid om alle gebieden van het
            bewegingsapparaat te beoordelen en diagnosticeren. Dat betekent dat
            indien er een probleem is in of rond de wervelkolom, gewrichten en
            spieren, een MDT evaluatie geschikt kan zijn.
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            MDT is een integrale aanpak van klachten van het bewegingsapparaat.
            Deze is gebaseerd op wetenschappelijk onderbouwde principes. Wanneer
            deze volledig begrepen en opgevolgd worden, kan deze zeer succesvol
            zijn.
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            <span
              color="textPrimary"
              style={{
                fontWeight: 700,
                marginBottom: "1rem",
              }}
            >
              Als u een van de volgende klachten heeft kan de Mckenzie methode
              voor u geschikt zijn:
            </span>
            <li>
              Ischias (Uitstralende pijn vanuit de rug naar één of beide benen)
            </li>
            <li>
              Gewrichtspijn in de heupen of billen, schouders of bovenarmen
            </li>
            <li>
              Moeite met buigen als gevolg van stijfheid of pijn in de onderrug
            </li>
            <li>
              Pijn die begint in de rug of de nek en lijkt te verspreiden naar
              de billen of schouders
            </li>
            <li>
              Wisselende tintelingen of gevoelloosheid in de voeten of handen
            </li>
            <li>
              Zeurende pijn in de ellebogen of knieën, die niet verbeteren door
              een behandeling van deze gewrichten
            </li>
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.imageGrid}
        >
          <img
            alt="McKenzie methode specialisatie"
            src={McKenzieMethodeSpecialisatieFoto}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default McKenzieMethode;
