import { Grid, Typography } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import ContactsData from "../components/ContactsData";
import OpeningsTijden from "../components/OpeningsTijden";
import Logo from "../images/fysiotherapie-nieuw-vennep-boost-logo-size-4.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemFirst: {
      alignItems: "center",
      paddingBottom: "1rem",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    gridItemLast: {
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
      },
    },
    higherLevelGrids: {
      paddingTop: "0.5rem",
      background: "linear-gradient(#4b0340, black)",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "4rem",
        paddingRight: "4rem",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "5rem",
        paddingRight: "5rem",
      },
    },
    logo: {
      [theme.breakpoints.down("xs")]: {},
    },
  })
);

export default function MainPageLast() {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        className={classes.higherLevelGrids}
      >
        <Grid
          container
          item
          direction="column"
          xs={12}
          sm={6}
          md={4}
          lg={4}
          className={classes.gridItemFirst}
        >
          <Typography
            component="span"
            variant="h5"
            color="textPrimary"
            align="left"
            style={{
              textAlign: "left",
              marginLeft: "2rem",
              fontWeight: 700,
              color: "rgb(255, 152, 0)",
            }}
          >
            Contact
          </Typography>

          <ContactsData />
        </Grid>

        <Grid
          container
          item
          direction="column"
          xs={12}
          sm={6}
          md={4}
          lg={4}
          className={classes.gridItemLast}
        >
          <Typography
            component="span"
            variant="h5"
            color="textPrimary"
            align="left"
            style={{
              textAlign: "left",
              marginLeft: "2rem",
              fontWeight: 700,
              color: "rgb(255, 152, 0)",
            }}
          >
            Openingstijden
          </Typography>

          <OpeningsTijden />
        </Grid>

        <Grid
          container
          item
          direction="column"
          xs={12}
          sm={12}
          md={4}
          lg={4}
          alignItems="center"
        >
          <img
            src={Logo}
            alt="Emilie-Wegman-Manueel Therapeut-Fysio Therapeut-Boost Fysiotherapie"
            className={classes.logo}
          />
        </Grid>
      </Grid>
    </div>
  );
}
