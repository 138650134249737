import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CardMedia,
} from "@material-ui/core";

import dryNeedling from "../images/dry-needling.jpg";
import manueletherapie from "../images/manual therapy.jpg";
import fysiothereaanhuis from "../images/fysiotherapie-aan-huis.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "transparent",
      borderStyle: "none",
    },
    dividerStyle: {
      backgroundColor: "rgba(46, 119, 113, 1)",
    },
    inline: {
      display: "inline",
    },
    fonts: {
      color: "white",
      fontFamily: "open sans!important",
    },
    card: {
      background: "rgba(251,251,251,1)",
      borderColor: "rgba(251,251,251,1)",
      borderStyle: "solid",
      marginBottom: "2rem",
      marginTop: "0.5rem",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "2rem",
        marginRight: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
      },
    },
    cardMedia: {
      height: 200,
      justifyContent: "center",
    },
  })
);

export default function SpecializationCards() {
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="row">
        <Grid container item direction="column" xs={12} sm={4} md={4}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={manueletherapie}
              title="Fysiotherapie en Manuele therapie"
            />
            <CardContent>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  component="span"
                  variant="h1"
                  align="center"
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 20,
                    margin: "0.2rem",
                  }}
                >
                  Fysiotherapie en Manuele therapie
                </Typography>
                <Typography
                  style={{ fontSize: 12, fontWeight: 500, color: "black" }}
                >
                  Als fysio/manueeltherapeut zoek ik aan de hand van uw
                  hulpvraag naar het beweeg en functioneringsprobleem. In
                  overeenstemming met uw wensen, mijn expertise en de meest
                  recente behandelmethodes bereiken we het beste resultaat!
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid container item direction="column" xs={12} sm={4} md={4}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={fysiothereaanhuis}
              title="Behandeling aan Huis"
            />
            <CardContent>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  component="span"
                  variant="h1"
                  align="center"
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 20,
                    margin: "0.2rem",
                  }}
                >
                  Behandeling aan Huis
                </Typography>
                <Typography
                  style={{ fontSize: 12, fontWeight: 500, color: "black" }}
                >
                  BOOST Fysiotherapie biedt ook fysiotherapie aan huis.
                  Fysiotherapie aan huis kan uitkomst bieden als u zelf (fysiek)
                  niet in staat bent om naar de praktijk te komen. Indien u een
                  behandeling aan huis wilt; neem dan contact op via
                  telefoon/email/whatsapp. Deze behandeling kunt u niet via het
                  afspraakportaal inplannen.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid container item direction="column" xs={12} sm={4} md={4}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={dryNeedling}
              title="Dry Needling"
            />
            <CardContent>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  component="span"
                  variant="h1"
                  align="center"
                  style={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: 20,
                    margin: "0.2rem",
                  }}
                >
                  Dry Needling
                </Typography>
                <Typography
                  style={{ fontSize: 12, fontWeight: 500, color: "black" }}
                >
                  Dry needling is een behandelmethode die past binnen de fysio
                  en manuele therapie. Deze techniek kan gebruikt worden bij
                  triggerpoints in spieren.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
