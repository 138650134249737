import { CssBaseline, Typography, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../stylesheets/App.css";
import Copyright from "./Copyright";
import NavigationBar from "../components/NavigationBar";
import RouteMap from "../components/RouteMap";
import ContactsData from "../components/ContactsData";
import OpeningsTijden from "../components/OpeningsTijden";
import TopBar from "../components/TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingTop: "0.5rem",
      fontWeight: 700,
      color: "rgba(46, 119, 113, 1)",
      marginBottom: "0.5rem",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        textAlign: "center",
        fontSize: 17,
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "center",
        textAlign: "center",
        fontSize: 17,
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "left",
        textAlign: "left",
        fontSize: 19,
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "left",
        textAlign: "left",
        fontSize: 19,
      },
    },
    beschrijving: {
      marginLeft: "2.5rem",
      marginRight: "2.5rem",
      color: "black",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        justifyContent: "center",
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        justifyContent: "center",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        justifyContent: "flex-start",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 14,
        justifyContent: "flex-start",
        textAlign: "left",
      },
    },
    mapGrid: {
      order: 2,
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        marginBottom: "2rem",
        order: 1,
      },
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
        order: 1,
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "flex-start",
        order: 2,
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        order: 2,
      },
    },
    infoGrid: {
      order: 1,
      [theme.breakpoints.down("xs")]: {
        order: 2,
      },
      [theme.breakpoints.up("sm")]: {
        order: 2,
      },
      [theme.breakpoints.up("md")]: {
        order: 1,
      },
      [theme.breakpoints.up("lg")]: {
        order: 1,
      },
    },
    mainGrid: {
      [theme.breakpoints.down("xs")]: {
        marginTop: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "3rem",
      },
    },
  })
);

function Contacts() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          BOOST Fysiotherapie / Contact / Manuele therapie / Dry needling /
          Nieuw Vennep
        </title>
        <meta
          name="description"
          content="Contact Adres: Elemastraat 65 2151 AJ Nieuw Vennep Telefoon: 0618223861 Email: info@boostfysiotherapie.nl Website: www.boostfysiotherapie.nl De praktijk is geopend buiten kantoortijden."
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Contact / Manuele therapie / Dry needling / Nieuw Vennep"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="flex-start"
        className={classes.mainGrid}
        direction="row"
      >
        <Grid
          container
          item
          direction="column"
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.mapGrid}
        >
          <RouteMap />
        </Grid>

        <Grid
          container
          item
          direction="row"
          xs={12}
          sm={12}
          md={7}
          lg={7}
          alignItems="flex-start"
          className={classes.infoGrid}
        >
          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            alignItems="center"
          >
            <Typography
              component="h1"
              color="textPrimary"
              align="left"
              className={classes.title}
            >
              Route Beschrijving
            </Typography>
            <Typography
              component="h1"
              color="textPrimary"
              align="left"
              className={classes.beschrijving}
            >
              BOOST Fysiotherapie is gevestigd in het ontmoetingscentrum aan het
              harmonieplein (vlakbij winkelcentrum de Symfonie) Er is voldoende
              (gratis) parkeergelegenheid. Bij binnenkomst de trap op (of lift),
              u loopt direct de wachtkamer in waar u plaats kunt nemen.
            </Typography>
          </Grid>

          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            alignItems="center"
            style={{ paddingTop: "0.5rem" }}
          >
            <Typography
              component="h1"
              color="textPrimary"
              align="left"
              className={classes.title}
            >
              Contact
            </Typography>

            <ContactsData itemStyle={1} />
          </Grid>

          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            alignItems="center"
            style={{ paddingTop: "0.5rem" }}
          >
            <Typography
              component="h1"
              color="textPrimary"
              align="left"
              className={classes.title}
            >
              Openingstijden
            </Typography>
            <OpeningsTijden itemStyle={1} />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default Contacts;
