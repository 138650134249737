import ListItemText from "@material-ui/core/ListItemText";
import { Link, Grid, makeStyles, Theme, createStyles } from "@material-ui/core";
import UseMenu from "./navigation/Dropdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexWrap: "nowrap",
      alignItems: "center !important",
    },
    font: {
      color: "#4b0340",
      marginLeft: "0.5rem",
      display: "flex",
      fontWeight: 700,
      marginTop: "auto",
      marginBottom: "auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
  })
);

const specialisatiesMenuItems = [
  { label: "Fysiotherapie", route: "/specialisaties/fysiotherapie" },
  { label: "Manuele therapie", route: "/specialisaties/manuele-therapie" },
  {
    label: "Orofaciale fysiotherapie",
    route: "/specialisaties/orofaciale-therapie",
  },
  { label: "Dry Needling", route: "/specialisaties/dry-needling" },
  { label: "Mckenzie Methode", route: "/specialisaties/mckenzie-methode" },
];

const informationMenuItems = [
  { label: "Praktijk Informatie", route: "/informatie/praktijk" },
  { label: "Tarieven", route: "/informatie/tarieven" },
];

export default function LinearNavContent() {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        justifyContent="space-around"
        direction="row"
        className={classes.root}
      >
        <UseMenu
          mainLabel="Specialisaties"
          menuItems={specialisatiesMenuItems}
        />

        <UseMenu mainLabel="Informatie" menuItems={informationMenuItems} />

        <Link
          href="/over"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
            padding: "5px",
          }}
        >
          <ListItemText>
            <strong className={classes.font}>Over de therapeut</strong>
          </ListItemText>
        </Link>

        <Link
          href="/contact"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
            padding: "5px",
          }}
        >
          <ListItemText>
            <strong className={classes.font}>Contact</strong>
          </ListItemText>
        </Link>
      </Grid>
    </div>
  );
}
