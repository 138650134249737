import { CssBaseline, Typography, Grid, Avatar } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../../stylesheets/App.css";
import OrofacialeTherapieSpecialisatieFoto from "../../images/orofaciale-therapie.jpg";
import Copyright from "../../view/Copyright";
import NavigationBar from "../NavigationBar";
import TopBar from "../TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: "#4b0340",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
    font: {
      padding: 0,
      color: "#3d072d",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "20rem",
        height: "25rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "25rem",
        height: "30rem",
        marginLeft: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30rem",
        height: "30rem",
      },
    },
    boodschap: {
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        marginLeft: "2rem",
        marginRight: "2rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        marginLeft: "4rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginLeft: "6rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        marginLeft: "6rem",
        textAlign: "left",
      },
    },
    imageGrid: {
      alignItems: "center",
      order: 2,
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        marginBottom: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        marginBottom: "8rem",
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        marginBottom: "8rem",
      },
    },
    infoGrid: {
      marginTop: "1rem",
      order: 1,
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "2rem",
      },
    },
  })
);

function OrofacialTherapy() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important", marginBottom: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BOOST Fysiotherapie / Fysiotherapie</title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio, manuele en orofaciale therapie in Nieuw Vennep. De praktijkeigenaar is Emilie Wegman, Manueel therapeut - OMT Orthopedische Manuele Therapie"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Orofaciale fysiotherapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/specialisaties/orofaciale-therapie" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          alignItems="flex-start"
          className={classes.infoGrid}
        >
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{
              fontWeight: 700,
              fontSize: 24,
              color: "#4b0340",
              marginBottom: "1rem",
            }}
          >
            Orofaciale Fysiotherapie
          </Typography>

          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            De orofaciaal fysiotherapeut wordt ook wel kaakfysiotherapeut of
            hoofdfysiotherapeut genoemd. De orofaciaal fysiotherapeut helpt bij
            problemen met de spieren en gewrichten in het hoofd- of halsgebied
            en met het kauwstelsel. Orofaciaal fysiotherapeuten zijn geschoold
            in tandheelkundige aspecten, tinnitus (oorsuizen),
            duizeligheidsklachten en het evenwichtsorgaan, specifieke
            zenuwaandoeningen, kanker in het hoofd-halsgebied en specifieke
            stoornissen die te maken hebben met de nek. Ze werken nauw samen met
            huisartsen, tandartsen, gnathologen, mondhygiënisten, orthodontisten
            en medisch specialisten, zoals KNO-artsen, kaakchirurgen en
            neurologen.
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            <span
              color="textPrimary"
              style={{ fontWeight: 700, marginBottom: "1rem" }}
            >
              Klachten die een orofaciaal fysiotherapeut behandelt, zijn:
            </span>
            <li>Klemmen van de kaak en tandenknarsen (bruxisme);</li>
            <li>Pijn in het gezicht, kaak of hoofd;</li>
            <li>Oorsuizen (tinnitus);</li>
            <li>Aangezichtsverlamming (Bellse parese);</li>
            <li>Aspecifieke aangezichtspijn;</li>
            <li>Duizeligheid en BPPD;</li>
            <li>Nek- en schouderklachten;</li>
            <li>Hoofdpijn en vormen van migraine;</li>
            <li>Gevolgen van ongelukken en botbreuken;</li>
            <li>
              Klachten in het hoofd-halsgebied door kanker, reuma of een andere
              ziekte;
            </li>
            <li>
              Klachten in het hoofd-halsgebied die te maken hebben met spanning
              of stress.
            </li>
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.imageGrid}
        >
          <Avatar
            alt="Orofacialetherapie Specialisatie"
            src={OrofacialeTherapieSpecialisatieFoto}
            className={classes.avatar}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default OrofacialTherapy;
