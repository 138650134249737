import React from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";
import Afspraak from "./Afspraak";
import App from "./App";
import Contacts from "./Contacts";
import Afspraken from "./Afspraken";
import Fysiotherapie from "../components/specializations/Fysiotherapie";
import ManualTherapy from "../components/specializations/ManueleTherapie";
import OrofacialTherapy from "../components/specializations/OrofacialeTherapie";
import DryNeedling from "../components/specializations/DryNeedling";
import McKenzieMethode from "../components/specializations/McKenzieMethode";
import PraktijkInformatie from "../components/informatie/PraktijkInformatie";
import TarievenInformatie from "../components/informatie/TarievenInformatie";
import OverDeTherapeut from "./Praktijk";

// These props are provided by the router
interface PathProps {
  history: any;
  location: any;
  match: any;
}

class Container extends React.Component<RouteComponentProps<PathProps>> {
  public render() {
    return (
      <Switch>
        <Route exact={true} path="/" component={App} />
        <Route path="/afspraken" component={Afspraken} />
        <Route path="/afspraak" component={Afspraak} />
        <Route path="/contact" component={Contacts} />
        <Route path="/specialisaties/fysiotherapie" component={Fysiotherapie} />
        <Route
          path="/specialisaties/manuele-therapie"
          component={ManualTherapy}
        />
        <Route
          path="/specialisaties/orofaciale-therapie"
          component={OrofacialTherapy}
        />
        <Route path="/specialisaties/dry-needling" component={DryNeedling} />
        <Route
          path="/specialisaties/mckenzie-methode"
          component={McKenzieMethode}
        />

        <Route path="/informatie/praktijk" component={PraktijkInformatie} />

        <Route path="/informatie/tarieven" component={TarievenInformatie} />

        <Route path="/over" component={OverDeTherapeut} />

        <Route path="/**" component={App} />
      </Switch>
    );
  }
}

export default withRouter(Container);
