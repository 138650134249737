import { Grid } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import StarRating from "./reviews/StarRating";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    higherLevelGrids: {
      paddingTop: "0.1rem",
      background: "#4b0340",
      width: "100%",
      height: "2rem",
    },
    buttonGrid: {
      [theme.breakpoints.between(0, 370)]: {
        maxWidth: "33.3333%",
      },
      [theme.breakpoints.up(370)]: {
        maxWidth: "33.33333%",
      },
    },
    icon: {
      color: "white",
      justifyContent: "center",
      fontFamily: "open sans !important",
      fontWeight: 600,
      padding: 0.25,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
      "@media (max-width: 320px)": {
        fontSize: "12px !important",
      },
    },
  })
);

export default function AfterCarousel() {
  const classes = useStyles();

  const setFirstGrid = () => {
    return (
      <Grid
        container
        justifyContent="space-around"
        className={classes.higherLevelGrids}
      >
        <Grid
          container
          xs={12}
          justifyContent="center"
          style={{ flexWrap: "nowrap", gap: "3em" }}
        >
          <StarRating
            rating={4.8}
            url="https://www.zorgkaartnederland.nl/zorginstelling/fysiotherapiepraktijk-boost-fysiotherapie-nieuw-vennep-10017653/waardering"
            reviewCount={43}
            icon="https://www.zorgkaartnederland.nl/build/images/elements/logo.svg" // Custom icon URL
          />

          <StarRating
            rating={4.9}
            url="https://www.google.com/search?sca_esv=468619604d8ac239&sca_upv=1&hl=en-NL&gl=nl&q=BOOST+Fysio+en+Manuele+therapie,+Elemastraat+65,+2151+AM+Nieuw-Vennep&uds=ADvngMgtiFEu3efgeoec760IkvNy4Uy9vxYhUC5xMRZw-bg1Umoqp8gXwemFatpgm7hQjAjXvJhdBwDVpLuLN2C98a3hmqnzC7upx4hcHXz97UXHp_MIVrxQpT3pYlIj6acxcGiB00zzhjUj-CE7LamS0B0uJZvug_k-Y8WBW2kTzya5oIujLt1HdVJGFxtWTmkYOYoY-_h5QB7e-wO41iBypXIBa4FvQA&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7lt4hE7q_WS3dcTstZkHgF47HX4wtryI6aSIll7XVR-WdIR4v8Os2drYtmhB7hPmL-Fi6nY%3D&sa=X&ved=2ahUKEwi85b29yaCIAxU5_gIHHWN3APIQ3PALegQIGBAE&biw=1536&bih=707&dpr=2.5"
            reviewCount={14}
            icon={faGoogle as IconProp} // Pass the Google icon
            iconSize="1x" // Optional: adjust the size of the icon
          />
        </Grid>
      </Grid>
    );
  };

  return <>{setFirstGrid()}</>;
}
