import React from "react";
import styled from "styled-components";

// Define image paths
import backgroundImageJpg from "../../images/fysiotherapie-nieuw-venne-boost-original.jpg";
import backgroundImageJpg75p from "../../images/fysiotherapie-nieuw-venne-boost-original-75p.jpg";
import backgroundImageJpg50p from "../../images/fysiotherapie-nieuw-venne-boost-original-50p.jpg";

// Define breakpoints
const breakpoints = {
  xs: "320px",
  sm: "480px",
  md: "768px",
  lg: "1200px",
};

// Styled component for the background image container
const BackgroundContainer = styled.div`
  width: 100%;
  height: 63vh; /* Default height; adjust as needed */
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  /* Default (larger screens) */
  background-image: url(${backgroundImageJpg});

  /* Small screens */
  @media (max-width: ${breakpoints.xs}) {
    background-image: url(${backgroundImageJpg50p});
  }

  /* Medium screens */
  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm}) {
    background-image: url(${backgroundImageJpg50p}) !important;
  }

  /* Larger screens */
  @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}) {
    background-image: url(${backgroundImageJpg75p}) !important;
  }

  @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    background-image: url(${backgroundImageJpg75p}) !important;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${backgroundImageJpg}) !important;
    height: 80vh;
  }
`;

const CarouselResponsiveImage: React.FC = () => (
  <BackgroundContainer>
    {/* Overlay content will be positioned absolutely */}
  </BackgroundContainer>
);

export default CarouselResponsiveImage;
