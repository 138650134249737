import { Typography } from "@material-ui/core";

const Copyright = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ color: "#ffc107" }}
    >
      {"Copyright © "}
      BOOST Fysiotherapie {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
