import { CssBaseline, Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Copyright from "./Copyright";
import "../stylesheets/App.css";

import NavigationBar from "../components/NavigationBar";
import TopBar from "../components/TopBar";
import CarouselContent from "../components/Carousel";
import AfterCarousel from "../components/AfterCarousel";
import KeyMessage from "../components/KeyMessage";
import SpecializationCards from "../components/SpecializationCards";
import MainPageLast from "../components/MainPageLast";

function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          BOOST Fysiotherapie / Manuele therapie / Dry Needling / Nieuw Vennep
        </title>
        <meta
          name="description"
          content="BOOST Fysiotherapie voor Fysiotherapie, Orthopedisch Manuele therapie, Orofaciale Fysiotherapie, Dry needling, Mckenzie Methode en Medical taping in Nieuw Vennep"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Dry Needling / Orofaciale Therapie / Nieuw Vennep"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://boostfysiotherapie.nl/" />
      </Helmet>

      <CssBaseline />
      <TopBar />
      <NavigationBar />

      <CarouselContent />

      <AfterCarousel />
      <KeyMessage />

      <SpecializationCards />

      <MainPageLast />

      <Grid
        container
        justifyContent="center"
        style={{
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          background: "black",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default App;
