import React from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  StarRatingContainer,
  Stars,
  ReviewCountContainer,
  ReviewCount,
  IconContainer,
  EmptyStar,
  FilledStar,
  CustomIcon,
} from "./styles";

interface StarRatingProps {
  rating: number; // This can now be a float like 4.7, 4.8, etc.
  url: string;
  reviewCount: number; // Number of reviews to display
  icon: IconProp | string; // The icon can be a FontAwesome icon or a URL
  iconSize?: SizeProp; // Size for the icon (if using FontAwesome)
}

const StarRating: React.FC<StarRatingProps> = ({
  rating,
  url,
  reviewCount,
  icon,
  iconSize = "1x",
}) => {
  const filledStars = Math.floor(rating); // Number of fully filled stars
  const partialFill = rating % 1; // The fractional part of the star
  const emptyStars = 5 - filledStars - (partialFill > 0 ? 1 : 0); // Remaining empty stars

  const stars = (
    <>
      {[...Array(filledStars)].map((_, index) => (
        <FilledStar key={`full-${index}`} icon={faStar as IconProp} />
      ))}
      {partialFill > 0 && (
        <FilledStar
          key="partial"
          icon={faStar as IconProp}
          style={{
            clipPath: `inset(0 ${100 - partialFill * 100}% 0 0)`,
            WebkitClipPath: `inset(0 ${100 - partialFill * 100}% 0 0)`,
          }}
        />
      )}
      {[...Array(emptyStars)].map((_, index) => (
        <EmptyStar key={`empty-${index}`} icon={faStar as IconProp} />
      ))}
    </>
  );

  return (
    <StarRatingContainer href={url} target="_blank" rel="noopener noreferrer">
      {typeof icon === "string" ? (
        <CustomIcon src={icon} alt="Custom Icon" />
      ) : (
        <IconContainer icon={icon} size={iconSize} />
      )}
      <div>
        <Stars>{stars}</Stars>
      </div>
      <ReviewCountContainer>
        <ReviewCount>{reviewCount}</ReviewCount>
      </ReviewCountContainer>
    </StarRatingContainer>
  );
};

export default StarRating;
