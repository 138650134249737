import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styled components
export const StarRatingContainer = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s;
  position: relative; /* For positioning the review count */

  &:hover {
    transform: scale(1.05);
  }
`;

export const Stars = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 5px; /* Space between icon and stars */
`;

export const IconContainer = styled(FontAwesomeIcon)`
  color: #ea4335; /* Default color */
  background: linear-gradient(
    90deg,
    #4285f4 25%,
    #34a853 25%,
    #34a853 50%,
    #fbbc05 50%,
    #fbbc05 75%,
    #ea4335 75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const FilledStar = styled(FontAwesomeIcon)`
  color: #ffd700; /* Gold color for filled stars */
  height: 0.7em;
`;

export const EmptyStar = styled(FontAwesomeIcon)`
  color: #d3d3d3; /* Light grey for empty stars */
  height: 0.7em;
`;

export const HalfStar = styled(FontAwesomeIcon)`
  color: #ffd700; /* Gold color for half stars */
  height: 0.7em;
`;

export const ReviewCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  background-color: transparent; /* Background color of the circle */
  border-radius: 50%;
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  color: white;
  font-size: 12px; /* Adjust font size as needed */
  text-align: center;
  line-height: 25px; /* Center text vertically */
  margin-left: 5px;
  border: 1px solid white;
`;

export const ReviewCount = styled.span`
  font-weight: bold;
  color: white;
`;

export const CustomIcon = styled.img`
  width: 5em; /* Adjust size as needed */
  height: 18px; /* Adjust size as needed */
  margin-right: 10px; /* Space between icon and stars */
  background: white;
`;
