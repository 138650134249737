import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import reportWebVitals from './reportWebVitals';
import Container from "./view/Container";
import { Route, Router } from "react-router";
import { createBrowserHistory } from "history";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ReactGA from "react-ga4";

const history = createBrowserHistory();

ReactGA.initialize("G-K6VRFTVWY6");

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Route path="/" component={Container} />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
