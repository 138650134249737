import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../../stylesheets/App.css";
import Copyright from "../../view/Copyright";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "white !important",
      width: "100%",
      marginBottom: "1rem",
    },
    items: { padding: 0 },
    title: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        marginLeft: "1rem",
        textAlign: "center",
        fontSize: 16,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "center",
        marginLeft: "1rem",
        textAlign: "left",
        fontSize: 18,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "left",
        marginLeft: "1rem",
        textAlign: "left",
        fontSize: 18,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "left",
        marginLeft: "1rem",
        textAlign: "left",
        fontSize: 22,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
    },
    details: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        fontWeight: 400,
        color: "black",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        fontWeight: 400,
        color: "black",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        fontWeight: 400,
        color: "black",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        fontWeight: 400,
        color: "black",
      },
    },
  })
);

function Tarieven() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        className={classes.items}
        direction="row"
      >
        <List>
          <ListItem style={{ paddingBottom: "0", margin: "0" }}>
            <ListItemText
              style={{ color: "white" }}
              secondary={
                <div>
                  <Typography component="h1" className={classes.details}>
                    Per 1 Juli 2023 gelden de onderstaande tarieven voor als u
                    niet aanvullend verzekerd bent voor fysiotherapie:
                  </Typography>
                </div>
              }
            ></ListItemText>
          </ListItem>

          <ListItem
            style={{
              paddingTop: "0",
              paddingBottom: "0",
              marginLeft: "0rem",
            }}
          >
            <ListItemText
              style={{ color: "white" }}
              secondary={
                <div>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> Intake en onderzoek na screening:
                    &euro;60,-
                  </Typography>

                  <Typography className={classes.details}>
                    <span>&#8226;</span> Intake en onderzoek na verwijzing:
                    &euro;60,-
                  </Typography>

                  <Typography className={classes.details}>
                    <span>&#8226;</span> Zitting manuele therapie in de
                    praktijk: &euro;50,-
                  </Typography>

                  <Typography className={classes.details}>
                    <span>&#8226;</span> Zitting fysiotherapie in de praktijk:
                    &euro;40,-
                  </Typography>

                  <Typography className={classes.details}>
                    <span>&#8226;</span> Toeslag aan huis: &euro;10,-
                  </Typography>

                  <Typography className={classes.details}>
                    <span>*</span>Gemiste afspraak: &euro;35,-
                  </Typography>

                  <Typography className={classes.details}>
                    <span>*</span>Indien u later dan 24 uur van te voren afmeld
                  </Typography>
                </div>
              }
            ></ListItemText>
          </ListItem>

          <ListItem
            style={{
              paddingTop: "0",
              paddingBottom: "0",
              marginLeft: "0.3rem",
            }}
          >
            <ListItemText
              style={{ color: "white" }}
              secondary={
                <div>
                  <Typography component="h1" className={classes.details}>
                    <strong>
                      BOOST Fysiotherapie heeft een zorgovereenkomst met:
                    </strong>
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> VGZ (VGZ, IZA, IZZ, Unive, UMC
                    verzekeringen, Zorgzaam, Bewuzt, Zekur en SZVK)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> Achmea (Zilveren kruis, Interpolis,
                    Prolife, FBTO, De Friesland, Zilveren kruis ZieZo)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> CZ (CZ, Delta Lloyd, Nationale
                    Nederlanden, OHRA) (Let op: Just is hierbij uitgesloten.)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> Menzis (Menzis, HEMA, Anderzorg, PMA)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> ONVZ (ONVZ, PNOzorg, VvAA, jaaah.)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> Zorg en zekerheid
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> DSW (DSW, stad holland, internet
                    zorgverzekeraar)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> Eno (Salland, ZorgDirect)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> A.S.R (Let op: Wij hebben alleen een
                    overeenkomst voor de Amersfoortse restitutiepolis en de
                    Ditzo combinatiepolis.)
                  </Typography>
                  <Typography className={classes.details}>
                    De naturapolis van Ditzo en de naturapolis van De
                    Amersfoortse zijn uitgesloten.)
                  </Typography>
                  <Typography className={classes.details}>
                    <span>&#8226;</span> Caresq (Eucare)
                  </Typography>
                </div>
              }
            ></ListItemText>
          </ListItem>
        </List>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default Tarieven;
