import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "transparent",
      padding: 0,
      margin: 0,
      fontFamily: "Montserrat",
    },
    dividerStyle: {
      backgroundColor: "#ccf5f1",
    },
    inline: {
      display: "inline",
    },
    itemStyle1: {
      fontSize: 12,
      color: "white",
      margin: "0",
    },
    itemStyle2: {
      fontSize: 15,
      color: "black",
      margin: "0",
    },
  })
);

interface Props {
  itemStyle?: number;
}

export default function OpeningsTijden(props: Readonly<Props>) {
  const classes = useStyles();
  let itemStyle = classes.itemStyle1;

  if (props.itemStyle === 1) {
    itemStyle = classes.itemStyle2;
  }

  return (
    <div>
      <List className={classes.root}>
        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: "white" }}
            secondary={
              <Typography className={itemStyle}>
                Maandag - 14:00-21:00
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: "white" }}
            secondary={
              <Typography className={itemStyle}>
                Dinsdag - 09:00-16:00
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <Typography className={itemStyle}>
                Woensdag - Gesloten{" "}
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: "white" }}
            secondary={
              <Typography className={itemStyle}>
                Donderdag - 09:00-17:00
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: "white" }}
            secondary={
              <Typography className={itemStyle}>Vrijdag - Gesloten </Typography>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: "white" }}
            secondary={
              <Typography className={itemStyle}>
                Zaterdag - 8:00-14:00{" "}
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faCalendarDay as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: "white" }}
            secondary={
              <Typography className={itemStyle}>Zondag - Gesloten </Typography>
            }
          ></ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
