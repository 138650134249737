import {
  AppBar,
  Button,
  Container,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import LinearNavContent from "./LinearNavContent";
import CustomizedMenus from "./NavBarMenu";
import logo from "../images/fysiotherapie-nieuw-vennep-boost-logo.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      paddingBottom: "5px",
      [theme.breakpoints.up("xs")]: {
        paddingLeft: "15px",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "15px",
        paddingRight: "50px",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "15px",
        paddingRight: "50px",
      },
    },
    toolbar: {
      padding: 0,
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: "center",
      padding: 0,
      margin: 0,
    },
    logo: {
      pointerEvents: "none",
      padding: 0,
      height: "3.2rem",
      marginLeft: "1rem",
    },
    afspraakButton: {
      color: "white",
      background: "#4b0340",
      height: "2rem",
      padding: "0.5rem",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      marginLeft: "0.5rem",
      marginTop: "auto",
      marginBottom: "auto",
      textTransform: "capitalize",
      justifyContent: "center",
      "&:hover": {
        background: "rgb(133, 23, 157)",
      },
      "@media (max-width: 400px)": {
        display: "none",
      },
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
  })
);

const NavigationBar = () => {
  const classes = useStyles();
  const setMenuItems = () => {
    const mql = window.matchMedia("(max-width: 900px)");
    const isMobile = mql.matches;
    if (isMobile) {
      return <div>{CustomizedMenus()}</div>;
    } else {
      return <div>{LinearNavContent()}</div>;
    }
  };
  return (
    <AppBar className={classes.root} position="static">
      <Toolbar>
        <Container maxWidth="lg" className={classes.navDisplayFlex}>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            xs={3}
            style={{ flexWrap: "nowrap" }}
          >
            <Link
              href="/"
              style={{
                textDecorationColor: "rgba(46, 119, 113, 1)",
                margin: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="Fysiotherapie Nieuw Vennep - Boost Fysiotherapie Logo - fysio - Emilie Wegman"
                className={classes.logo}
              />
            </Link>
          </Grid>
          <Grid
            container
            item
            direction="row"
            xs={9}
            justifyContent="flex-end"
            style={{ textAlign: "end", margin: "0.2rem" }}
            alignItems="center"
          >
            {setMenuItems()}
            <Button href="/afspraken" className={classes.afspraakButton}>
              Afspraak Maken
            </Button>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
export default NavigationBar;
