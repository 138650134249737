import {
  CssBaseline,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Link,
  Tooltip,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../stylesheets/App.css";
import Copyright from "./Copyright";
import NavigationBar from "../components/NavigationBar";
import { WhatsApp, MailOutlined, Call } from "@material-ui/icons";
import TopBar from "../components/TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      "&:hover $focusHighlight": {
        opacity: 0,
        background: "transparent",
      },
      height: "fit-content",
    },
    infoGrid: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0.25rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "0.25rem",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "0.25rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "0.25rem",
      },
    },
    card: {
      background:
        "linear-gradient(135deg, #ecfaf3 25%, #e0faf8 75%, rgb(193 248 255) 100%)",
      borderRadius: "1rem",
      display: "grid",
      [theme.breakpoints.between(0, 320)]: {
        width: 300,
        height: 300,
      },
      [theme.breakpoints.between(321, 400)]: {
        width: 325,
        height: 300,
      },
      [theme.breakpoints.between(401, 500)]: {
        width: 375,
        height: 300,
      },
      [theme.breakpoints.between(501, 600)]: {
        width: 475,
        height: 300,
      },
      [theme.breakpoints.between(601, 700)]: {
        width: 575,
        height: 250,
      },
      [theme.breakpoints.between(701, 800)]: {
        width: 675,
        height: 250,
      },
      [theme.breakpoints.between(801, 900)]: {
        width: 775,
        height: 250,
      },
      [theme.breakpoints.between(901, 1000)]: {
        width: 800,
        height: 250,
      },
      [theme.breakpoints.between(1001, 1200)]: {
        width: 800,
        height: 250,
      },
      [theme.breakpoints.up(1201)]: {
        width: 800,
        height: 250,
      },
    },
    title1: {
      marginTop: "1rem",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 28,
      fontWeight: 700,
      color: "white",
      textShadow: "0.5px 0.5px 0.5px #ffeb3b",
    },
    title2: {
      marginTop: "1rem",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 28,
      fontWeight: 700,
      color: "black",
      textShadow: "0.5px 0.5px 0.5px #ffeb3b",
    },
    content: {
      justifyContent: "center",
      textAlign: "center",
      fontSize: 12,
      fontWeight: 400,
    },
    action: {
      justifyContent: "center",
      textAlign: "center",
      height: "fit-content",
    },
    button: {
      color: "white",
      background: "rgba(202,25,85,1)",
      borderRadius: "12px",
      fontWeight: "bold",
      padding: "0.25rem",
      textDecoration: "none",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        width: "11rem",
        height: "2.25rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
        width: "11rem",
        height: "2.25rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 20,
        width: "11rem",
        height: "2.25rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  })
);

function Afspraken() {
  const classes = useStyles();
  return (
    <div style={{ background: "white", justifyContent: "center" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          BOOST Fysiotherapie / Afspraak / Manuele therapie / Dry needling
        </title>
        <meta
          name="description"
          content="Contact Adres: Elemastraat 65 2151 AM Nieuw Vennep Telefoon: 0618223861 Email: info@boostfysiotherapie.nl Website: www.boostfysiotherapie.nl"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Afspraak / Manuele therapie / Dry needling / Orofaciaal therapie"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/afspraken" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: "1rem",
          gap: "1rem",
          minHeight: "calc(100vh - 64px - 48px)",
        }}
        direction="row"
      >
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          className={classes.infoGrid}
        >
          <Card
            className={classes.card}
            style={{
              background:
                "linear-gradient(180deg, rgb(1 183 168) 25%, rgb(149 248 240) 75%, rgb(132 240 231) 100%)",
            }}
          >
            <CardActionArea className={classes.actionArea}>
              <CardContent>
                <Typography component="h1" className={classes.title1}>
                  Wilt u een afspraak maken?
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.action}>
              <Grid container justifyContent="center" direction="row">
                <Grid container justifyContent="center" direction="row">
                  <Link href="/afspraak" className={classes.button}>
                    Maak Afspraak
                  </Link>
                </Grid>
                <Grid container justifyContent="center" direction="row">
                  <Typography
                    variant="h1"
                    component="h2"
                    style={{ fontSize: 12, color: "black", fontWeight: 500 }}
                  >
                    Indien u een behandeling aan huis wilt; neem dan contact op
                    via
                    <Tooltip
                      disableFocusListener
                      title="Phone"
                      style={{ padding: "0.2rem" }}
                    >
                      <IconButton href="tel:+31618223861">
                        <Call style={{ fontSize: 12, color: "#2196f3" }} />
                      </IconButton>
                    </Tooltip>{" "}
                    of
                    <Tooltip
                      disableFocusListener
                      title="Whatsapp"
                      style={{ padding: "0.2rem" }}
                    >
                      <IconButton href="https://api.whatsapp.com/send/?phone=31618223861&text=Boost%20Fysiotherapie,%20hoe%20kunnen%20we%20helpen?&&app_absent=0">
                        <WhatsApp style={{ fontSize: 12, color: "green" }} />
                      </IconButton>
                    </Tooltip>{" "}
                    of
                    <Tooltip
                      disableFocusListener
                      title="Email"
                      style={{ padding: "0.2rem" }}
                    >
                      <IconButton href="mailto:info@boostfysiotherapie.nl?Subject=Afspraak%20annulering/wijziging">
                        <MailOutlined
                          style={{ fontSize: 12, color: "#e91e63" }}
                        />
                      </IconButton>
                    </Tooltip>
                    . Deze behandeling kunt u niet via het afspraakportaal
                    inplannen.
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
            <CardContent style={{ textAlign: "center" }}>
              <Typography
                variant="h1"
                component="h2"
                style={{ fontSize: 12, color: "black", fontWeight: 500 }}
              >
                * U kunt informatie over verzekeringen en onze tarieven{" "}
                <Link href="/tarieven">
                  <u>
                    {" "}
                    <strong>hier</strong>
                  </u>
                </Link>{" "}
                vinden.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          container
          item
          direction="column"
          alignItems="center"
          className={classes.infoGrid}
        >
          <Card
            className={classes.card}
            style={{
              background:
                "linear-gradient(135deg, #ff865f 25%, #ff865f 75%, #ff865f 100%)",
            }}
          >
            <CardActionArea className={classes.actionArea}>
              <CardContent>
                <Typography component="h1" className={classes.title2}>
                  Wilt u een afspraak annuleren/wijzigen?
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.action}>
              <Tooltip disableFocusListener title="Whatsapp">
                <IconButton href="https://api.whatsapp.com/send/?phone=31618223861&text=Boost%20Fysiotherapie,%20hoe%20kunnen%20we%20helpen?&&app_absent=0">
                  <WhatsApp style={{ fontSize: 40, color: "green" }} />
                </IconButton>
              </Tooltip>
              <Tooltip disableFocusListener title="Email">
                <IconButton href="mailto:info@boostfysiotherapie.nl?Subject=Afspraak%20annulering/wijziging">
                  <MailOutlined style={{ fontSize: 40, color: "#e91e63" }} />
                </IconButton>
              </Tooltip>
              <Tooltip disableFocusListener title="Phone">
                <IconButton href="tel:+31618223861">
                  <Call style={{ fontSize: 40, color: "#2196f3" }} />
                </IconButton>
              </Tooltip>
            </CardActions>
            <CardContent style={{ textAlign: "center" }}>
              <Typography
                variant="h1"
                component="h2"
                style={{ fontSize: 12, color: "#07006f", fontWeight: 500 }}
              >
                * Indien u teruggebeld wilt worden buiten openingstijden, spreek
                dan de voicemail in.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default Afspraken;
