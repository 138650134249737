import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faMapMarkerAlt,
  faEnvelope,
  faNotesMedical,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  ListItem,
  ListItemIcon,
  Typography,
  List,
  ListItemText,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "transparent",
      padding: "0",
      margin: "0",
      fontFamily: "Montserrat",
    },
    dividerStyle: {
      backgroundColor: "#ccf5f1",
    },
    inline: {
      display: "inline",
    },
    itemStyle1: {
      fontSize: 12,
      color: "white",
      margin: "0",
      marginLeft: "0.15rem",
      marginRight: "0.15rem",
      padding: "0",
    },
    itemStyle2: {
      fontSize: 15,
      color: "black",
      margin: "0",
      marginLeft: "0.15rem",
      marginRight: "0.15rem",
      padding: "0",
    },
    icons: {
      color: "rgba(255, 152, 0, 1)",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
  })
);

interface Props {
  itemStyle?: number;
}

export default function ContactsData(props: Readonly<Props>) {
  const classes = useStyles();
  let itemStyle = classes.itemStyle1;

  if (props.itemStyle === 1) {
    itemStyle = classes.itemStyle2;
  }

  return (
    <div>
      <List className={classes.root}>
        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faHospital as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <div>
                <Typography className={itemStyle}>
                  BOOST Fysiotherapie
                </Typography>
              </div>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <div>
                <Typography className={itemStyle}>
                  Elemastraat 65, 2151AJ, Nieuw-Vennep
                </Typography>
              </div>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faEnvelope as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <div>
                <Tooltip disableFocusListener title="Email">
                  <IconButton
                    href="mailto:info@boostfysiotherapie.nl?Subject=Afspraak%20annulering/wijziging"
                    className={itemStyle}
                  >
                    info@boostfysiotherapie.nl
                  </IconButton>
                </Tooltip>
              </div>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faMobileAlt as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <div>
                <Tooltip disableFocusListener title="Phone">
                  <IconButton href="tel:+31618223861" className={itemStyle}>
                    0618223861
                  </IconButton>
                </Tooltip>
              </div>
            }
          ></ListItemText>
        </ListItem>

        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faNotesMedical as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <div>
                <Typography className={itemStyle}>
                  {" "}
                  Tijdens openingstijden: Telefonisch bereikbaar
                </Typography>
              </div>
            }
          ></ListItemText>
        </ListItem>
        <ListItem style={{ padding: "0", margin: "0" }}>
          <ListItemIcon style={{ justifyContent: "center" }}>
            <FontAwesomeIcon
              icon={faNotesMedical as IconProp}
              size="1x"
              style={{ minWidth: "1.5rem", color: "rgba(255, 152, 0, 1)" }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={
              <div>
                <Typography className={itemStyle}>
                  Buiten openingstijden: Whatsapp of Email
                </Typography>
              </div>
            }
          ></ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
