import { CssBaseline, Grid, Container } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../../stylesheets/App.css";
import Copyright from "../../view/Copyright";
import NavigationBar from "../NavigationBar";
import TopBar from "../TopBar";
import AlgemeneInformatieItems from "./AlgemeneInformatieItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    items: {
      paddingTop: "1rem",
    },
    accordion: {
      width: "100% !important",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight: 600,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.text.secondary,
      marginLeft: "1rem",
    },
    details: {
      wordBreak: "break-word",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        color: "black",
        fontWeight: 400,
      },
    },
  })
);

function PraktijkInformatie() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important", marginBottom: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BOOST Fysiotherapie / Fysiotherapie</title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio, manuele en orofaciale therapie in Nieuw Vennep. De praktijkeigenaar is Emilie Wegman, Manueel therapeut - OMT Orthopedische Manuele Therapie"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Orofaciale fysiotherapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/informatie/praktijk" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Container style={{ height: "100%" }}>
        <Grid container justifyContent="flex-start" className={classes.items}>
          <AlgemeneInformatieItems />
        </Grid>
      </Container>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default PraktijkInformatie;
