import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    accordion: {},
    accordionDetails: { padding: 0 },
    heading: {
      fontSize: theme.typography.pxToRem(19),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight: 600,
      padding: 0,
      margin: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.text.secondary,
      marginLeft: "1rem",
    },
    details: {
      wordBreak: "break-word",
      margin: 0,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        color: "black",
        fontWeight: 400,
      },
    },
  })
);

export default function AlgemeneInformatieItems() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography component="h1" variant="h1" className={classes.heading}>
            Werkwijze
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <List style={{ padding: "0px" }}>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Bij het aanmelden zonder verwijzing (Directe
                      Toegankelijkheid fysiotherapie / DTF) wordt er naast het
                      vraaggesprek tevens een screening uitgevoerd. Deze
                      screening omvat aanvullende vragen naast het reguliere
                      vraaggesprek. Dit dient ervoor om na te gaan of er een
                      indicatie is voor fysio- of manuele therapie. Indien er
                      een indicatie is kan het onderzoek plaatsvinden.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Na het vraaggesprek en het onderzoek worden de bevindingen
                      met u besproken en wordt er een behandelplan opgemaakt.
                      HIerbij worden de werkhypothese(s), de behandeling en het
                      tijdspad besproken.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Vervolgens kan er gestart worden met de behandeling.
                      Tijdens het eerste bezoek kan er vaak al gestart worden
                      met de behandeling.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography component="h1" variant="h1" className={classes.heading}>
            Praktijkregels
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <List style={{ padding: "0px" }}>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      1. Gelieve bij het eerste consult een identificatiebewijs
                      mee te nemen
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      2. Gelieve bij elk consult een handdoek mee te nemen.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      3. U bent zelf verantwoordelijk om bij uw zorgverzekeraar
                      na te gaan hoeveel behandelingen u per jaar vergoed
                      krijgt. Mocht u elders al behandeling hebben gebruikt is
                      het handig om dit door te geven om te voorkomen dat u over
                      het aantal verzekerde behandelingen heen gaat. Indien u
                      niet of niet meer voldoende aanvullend verzekerd bent
                      krijgt u een nota met de geldende praktijk tarieven.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      4. Bij het niet tijdig afmelden (&lt;24 uur van te voren)
                      wordt de behandeling gerekend als een gemiste afspraak
                      zoals vermeld onder tarieven
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography component="h1" variant="h1" className={classes.heading}>
            KNGF klachtenregeling
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <List style={{ padding: "0px" }}>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Als therapeut probeer ik u een prettige en effectieve
                      behandeling te geven. Indien u toch ontevreden bent over
                      een behandeling is het goed om te weten dat de praktijk
                      deelneemt aan de klachtenregeling van de KNGF.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      U kunt in eerste instantie in gesprek gaan met mij om uw
                      klacht kenbaar te maken. Vanzelfsprekend ga ik zorgvuldig
                      om met uw klacht en zal ik deze naar tevredenheid proberen
                      op te lossen.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Indien dit gesprek niet het gewenste resultaat geeft kunt
                      u ook een klacht indienen via de klachtenprocedure.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem
              style={{ paddingBottom: "0", margin: "0", marginLeft: "0.2rem" }}
            >
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Voor meer informatie over
                      klachtenprocedure klikt dan:{" "}
                      <Link href="https://defysiotherapeut.com/klachtenregeling/">
                        <u>hier</u>
                      </Link>
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography component="h1" variant="h1" className={classes.heading}>
            Privacy policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <List style={{ padding: "0px" }}>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      De privacy policy is opgesteld om u te informeren betreft
                      de bescherming van uw persoonsgegevens. Wij doen er alles
                      aan om uw privacy te waarborgen en gaan daarom zorgvuldig
                      om met uw persoonsgegevens.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      BOOST Fysiotherapie houdt zich in alle gevallen aan de
                      toepasselijke wet- en regelgeving waaronder de AVG
                      (Algemene Verordening Gegevensbescherming) en de WGBO (Wet
                      geneeskundige behandelingsovereenkomst).
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Op grond van deze wetgeving heeft BOOST Fysiotherapie als
                      organisatie bepaalde plichten en heeft u als eigenaar van
                      de persoonsgegevens bepaalde rechten.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem
              style={{ paddingBottom: "0", margin: "0", marginLeft: "0.2rem" }}
            >
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      BOOST Fysiotherapie voldoet aan de plichten voortkomend
                      uit bovenstaande wetgeving. Dit houdt voor u in dat;
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Uw persoonsgegevens beperkt zijn tot
                      enkel de gegevens die nodig zijn voor de volgende
                      specifieke doeleinden:
                    </Typography>
                    <Typography className={classes.details}>
                      <span>-</span> Administratie in het patiëntendossier
                      noodzakelijk voor het behandelproces, declareren en
                      factureren
                    </Typography>
                    <Typography className={classes.details}>
                      <span>-</span> Het versturen van patiënttevredenheid
                      vragenlijsten na afloop van de behandeling
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Geen persoonsgegevens worden
                      doorgegeven aan andere partijen tenzij dit noodzakelijk is
                      voor de uitvoering van de doeleinden waarvoor de zijn
                      verstrekt. (denk aan uitwisseling met huisarts of ander
                      medisch specialist)
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Er vertrouwelijk wordt omgegaan met
                      de verstrekte gegevens
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Uw persoonsgegevens goed worden
                      beveiligd tegen onbevoegde toegang
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Uw persoonsgegevens niet langer
                      worden bewaard dan noodzakelijk, deze bewaartermijn is 15
                      jaar na beëindiging van het laatste consult. Voor meer
                      informatie:
                      <Link href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/gezondheid/medisch-dossier">
                        {" "}
                        https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/gezondheid/medisch-dossier
                      </Link>
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      <u style={{ fontWeight: 700 }}>
                        Een overzicht van de gevraagde persoonsgegevens:
                      </u>
                    </Typography>
                    <Typography className={classes.details}>
                      NAWTE (Naam, Adres + postcode, Woonplaats, Telefoonnummer
                      en Email), Geboortedatum, Geslacht, BSN, Beroep,
                      Sport/Hobbies, Woon/leefsituatie, Huisarts,
                      Zorgverzekering, Uw gezondheidsprofiel (denk aan; medische
                      voorgeschiedenis, huidige klachten etc.)
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem
              style={{
                paddingTop: "0",
                paddingBottom: "0",
                marginLeft: "0.2rem",
              }}
            >
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Uw rechten;
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Recht op kennis betreft de verzamelde
                      persoonsgegevens
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Recht op inzage en afname van uw
                      eigen gegevens*
                    </Typography>
                    <Typography className={classes.details}>
                      <span>&#8226;</span> Recht op correctie, aanvulling of
                      verwijdering van deze gegevens (mits deze gegevens op
                      grond van de wettelijke regeling niet bewaard moeten
                      blijven)*
                    </Typography>
                    <Typography className={classes.details}>
                      *persoonsgegevens van minderjarigen (&lt;16 jaar) dienen
                      een schriftelijke toestemming te hebben gegeven door de
                      ouder, verzorger of de wettelijke vertegenwoordiger.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <div>
                    <Typography className={classes.details}>
                      Indien u naar aanleiding van dit privacyreglement nog
                      vragen heeft kunt u altijd contact met ons opnemen.
                    </Typography>
                  </div>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
