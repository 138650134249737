import { CssBaseline, Typography, Grid, Avatar } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../../stylesheets/App.css";
import DryNeedlingSpecialisatieFoto from "../../images/dry-needling.jpg";
import Copyright from "../../view/Copyright";
import NavigationBar from "../NavigationBar";
import TopBar from "../TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: "#4b0340",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
    font: {
      padding: 0,
      color: "#3d072d",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "20rem",
        height: "25rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "25rem",
        height: "30rem",
        marginLeft: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30rem",
        height: "30rem",
      },
    },
    boodschap: {
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        marginLeft: "2rem",
        marginRight: "2rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        marginLeft: "4rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginLeft: "6rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        marginLeft: "6rem",
        textAlign: "left",
      },
    },
    imageGrid: {
      alignItems: "center",
      order: 2,
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        marginBottom: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        marginBottom: "18rem",
      },
    },
    infoGrid: {
      marginTop: "1rem",
      order: 1,
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "2rem",
      },
    },
  })
);

function DryNeedling() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important", marginBottom: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BOOST Fysiotherapie / Fysiotherapie</title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio, manuele en orofaciale therapie in Nieuw Vennep. De praktijkeigenaar is Emilie Wegman, Manueel therapeut - OMT Orthopedische Manuele Therapie"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Orofaciale fysiotherapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/specialisaties/dry-needling" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          alignItems="flex-start"
          className={classes.infoGrid}
        >
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{
              fontWeight: 700,
              fontSize: 24,
              color: "#4b0340",
              marginBottom: "1rem",
            }}
          >
            Dry Needling
          </Typography>

          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            Dry needling is een behandelmethode uit Canada en wordt al jaren
            toegepast binnen de reguliere fysiotherapie. Door middel van een
            dunne (acupunctuur)naald worden spieren aangeprikt en raken op die
            manier snel en langdurig ontspannen. Bij Dry needling wordt er
            gebruik gemaakt van een droge (dry) naald. Er wordt dus geen
            vloeistof in de spier gebracht. neurologen.
          </Typography>

          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ fontWeight: 700, marginBottom: "1rem" }}
          >
            Dry needling en Acupunctuur
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            Dry needling is niet hetzelfde als acupunctuur. Bij acupunctuur
            worden er vaak oppervlakkig meerdere naalden in het lichaam gezet.
            Deze naalden hebben een invloed op de energie van het lichaam. Bij
            Dry needling wordt er een specifiek punt in de spier aangeprikt
            (triggerpoint). Een triggerpoint is een verharding in een spier die
            zowel lokaal als uitstralende klachten kan veroorzaken.
            Triggerpoints kunnen de volgende klachten veroorzaken;
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            <span
              color="textPrimary"
              style={{
                fontWeight: 700,
                marginBottom: "1rem",
              }}
            >
              Hoe ontstaan triggerpoints?
            </span>
            <li>Pijn en stijfheid lokaal of uitstralende pijn</li>
            <li>Bewegingsbeperkingen in gewrichten</li>
            <li>Verminderde kracht in de betrokken spier</li>
            <li>Pijnontwijkend bewegen</li>
            <li>Tintelend gevoel in de armen/benen</li>
            <li>Hoofdpijnklachten en duizeligheid</li>
            <li>
              Acuut moment bv. door een verkeerde beweging (vertillen) of een
              ongeval of sportletsel
            </li>
            <li>Chronisch bv. door een langdurige verkeerde houding</li>
            <li>Immobiliteit bv. a.g.v. gips, brace of een sling</li>
            <li>Instabiliteit van gewrichten</li>
            <li>Stressfactoren</li>
          </Typography>
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ fontWeight: 700 }}
          >
            Wat voelt u van Dry needling?
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            Het inbrengen van het naaldje voelt u in principe niet. Als de
            triggerpoint wordt aangeprikt, spant de spier zich even aan. Dit kan
            even een soort kramp gevoel geven. Daarna kunnen de spieren
            tijdelijk wat zwaar en vermoeid aanvoelen (spierpijngevoel).
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.imageGrid}
        >
          <Avatar
            alt="Dry needling specialisatie"
            src={DryNeedlingSpecialisatieFoto}
            className={classes.avatar}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default DryNeedling;
