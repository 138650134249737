import {
  Grid,
  Link,
  Tooltip,
  IconButton,
  ButtonGroup,
  Hidden,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Call,
  KeyboardArrowDown,
  MailOutlined,
  WhatsApp,
} from "@material-ui/icons";
// @ts-ignore
import Scroll from "react-scroll";
import CarouselResponsiveImage from "./responsiveImage/carouselResponsiveImage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundInner: {
      position: "relative",
    },
    backgroundButtonGrids: {
      zIndex: 20,
      alignItems: "center",
      justifyContent: "center",
      gap: "1em",
      margin: "0px",
      position: "absolute",
      bottom: "10%" /* Adjust as needed */,
      left: "50%",
      transform: "translateX(-50%)",
      padding: "2em",
    },
    scrollDown: {
      position: "absolute",
      bottom: "10px",
      zIndex: 20,
      color: "white",
      fontSize: 60,
      paddingTop: "1rem",
      marginRight: "6px",
    },
    backGroundMessageGrid: {
      position: "absolute",
      top: "90%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        top: "95%",
      },
    },
    backgroundMessagePart: {
      color: "white",
      fontWeight: 600,
      [theme.breakpoints.up("xs")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 22,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 36,
      },
    },
    iconButtonGroup: {
      color: "white",
      background: "#4b0340",
      borderRadius: "0.2rem",
      width: "9rem",
      height: "2.5rem",
      padding: "0.5rem",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      marginLeft: "0.5rem",
      marginTop: "auto",
      marginBottom: "auto",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4b0340",
      },
      fontWeight: 700,
      gap: "0.2rem",
      justifyContent: "space-evenly",
    },
    button: {
      color: "white",
      background: "#4b0340",
      borderRadius: "0.2rem",
      fontSize: "15px",
      width: "9rem",
      height: "2.5rem",
      padding: "0.5rem",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      marginLeft: "0.5rem",
      marginTop: "auto",
      marginBottom: "auto",
      textTransform: "capitalize",
      justifyContent: "center",
      "&:hover": {
        background: "#4b0340",
      },
      fontWeight: 700,
    },
  })
);

function CarouselContent() {
  const classes = useStyles();
  const scroll = Scroll.animateScroll;

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        direction="row"
        style={{ position: "relative", background: "white" }}
      >
        <CarouselResponsiveImage />

        <Grid
          container
          item
          direction="column"
          className={classes.backGroundMessageGrid}
        >
          <Grid
            container
            item
            direction="row"
            className={classes.backgroundButtonGrids}
          >
            <Link href="/afspraken" className={classes.button}>
              Maak Afspraak
            </Link>

            <ButtonGroup className={classes.iconButtonGroup}>
              <Tooltip disableFocusListener title="Whatsapp">
                <IconButton
                  href="https://api.whatsapp.com/send/?phone=31618223861&text=Boost%20Fysiotherapie,%20hoe%20kunnen%20we%20helpen?&&app_absent=0"
                  style={{ padding: "0.25rem" }}
                >
                  <WhatsApp style={{ fontSize: "1.1em", color: "green" }} />
                </IconButton>
              </Tooltip>
              <Tooltip disableFocusListener title="Email">
                <IconButton
                  href="mailto:info@boostfysiotherapie.nl?Subject=Afspraak%20annulering/wijziging"
                  style={{ padding: "0.25rem" }}
                >
                  <MailOutlined style={{ fontSize: "1.1em", color: "white" }} />
                </IconButton>
              </Tooltip>
              <Tooltip disableFocusListener title="Phone">
                <IconButton
                  href="tel:+31618223861"
                  style={{ padding: "0.25rem" }}
                >
                  <Call style={{ fontSize: "1.1em", color: "#2196f3" }} />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>

        <Hidden smDown>
          <KeyboardArrowDown
            className={classes.scrollDown}
            onClick={() => scroll.scrollTo(500)}
          />
        </Hidden>
      </Grid>
    </div>
  );
}

export default CarouselContent;
