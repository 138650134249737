import * as React from "react";
import clsx from "clsx";
import { useMenu, MenuProvider } from "@mui/base/useMenu";
import { useMenuItem } from "@mui/base/useMenuItem";
import { Popper } from "@mui/base/Popper";
import { useDropdown, DropdownContext } from "@mui/base/useDropdown";
import { useMenuButton } from "@mui/base/useMenuButton";
import { Link } from "@material-ui/core";

const Menu = React.forwardRef(function Menu(
  props: React.ComponentPropsWithoutRef<"ul"> & { open: boolean },
  ref: React.Ref<HTMLUListElement>
) {
  const { children, open, ...other } = props;

  // Only passing the ref here, without trying to manage the open state inside useMenu
  const { triggerElement, contextValue, getListboxProps } = useMenu({
    listboxRef: ref,
  });

  return (
    <Popper open={open} anchorEl={triggerElement}>
      <ul className="menu-root" {...other} {...getListboxProps()}>
        <MenuProvider value={contextValue}>{children}</MenuProvider>
      </ul>
    </Popper>
  );
});

const MenuItem = React.forwardRef(function MenuItem(
  props: React.ComponentPropsWithoutRef<"li"> & { route?: string },
  ref: React.Ref<any>
) {
  const { children, onClick, route, ...other } = props;

  const { getRootProps, disabled, focusVisible } = useMenuItem({
    rootRef: ref,
  });

  const classes = {
    "focus-visible": focusVisible,
    "menu-item": true,
    disabled,
  };

  if (route) {
    return (
      <li className={clsx(classes)} {...other}>
        <Link href={route} className={clsx(classes)}>
          {children}
        </Link>
      </li>
    );
  }

  return (
    <li {...other} {...getRootProps({ onClick })} className={clsx(classes)}>
      {children}
    </li>
  );
});

interface MenuButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  function MenuButton(props, forwardedRef) {
    const { getRootProps } = useMenuButton({
      rootRef: forwardedRef,
    });

    return (
      <button
        type="button"
        {...props}
        {...getRootProps()}
        className={`button ${props.className ?? ""}`}
      />
    );
  }
);

export default function UseMenu({
  mainLabel,
  menuItems,
}: Readonly<{
  mainLabel: string;
  menuItems: { label: string; route?: string }[];
}>) {
  const { contextValue: dropdownContextValue } = useDropdown();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleMouseEnter = () => {
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setMenuOpen(false);
  };

  return (
    <React.Fragment>
      <DropdownContext.Provider value={dropdownContextValue}>
        {/* Trigger menu on hover */}
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ display: "inline-block" }}
        >
          <MenuButton>{mainLabel}</MenuButton>
          {/* Pass the open prop directly to the Popper, not useMenu */}
          <Menu id="hooks-menu" open={menuOpen}>
            {menuItems.map((menuItem, index) => (
              <MenuItem key={index} route={menuItem.route}>
                {menuItem.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </DropdownContext.Provider>
      <Styles />
    </React.Fragment>
  );
}

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

function Styles() {
  const styles = `
    .menu-root {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 1rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      min-width: 200px;
      background: white;
      border: 1px solid white;
      border-radius: 0.75em;
      color: "#4b0340";
      overflow: auto;
      outline: 0;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
    }

    .menu-item {
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
      user-select: none;
      color: #4b0340;
    }

    .menu-item:last-of-type {
      border-bottom: none;
    }

    .menu-item:focus {
      background-color: ${grey[100]};
      color: "${grey[900]}";
      outline: 0;
    }

    .menu-item.disabled {
      color: ${grey[400]};
    }

    .button {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5;
      padding: 8px 16px;
      border-radius: 8px;
      transition: all 150ms ease;
      cursor: pointer;
      background: white;
      border: none;
      color: #4b0340;

      &:hover {
        background: grey[50];
        border-color: grey[300];
      }

      &:active {
        background: grey[100];
      }

      &:focus-visible {
        box-shadow: 0 0 0 4px blue[200];
        outline: none;
      }
    }
  `;

  return <style dangerouslySetInnerHTML={{ __html: styles }} />;
}
