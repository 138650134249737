import { Grid, Typography } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    keyMessage: {
      color: "#4b0340",
      fontWeight: 600,
      textAlign: "center",
      margin: "0.5rem",
      [theme.breakpoints.up("xs")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
      "@media (max-width: 320px)": {
        fontSize: 16,
      },
    },
    bottomGrids: {
      background: "transparent",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      width: "100%",
    },
    icon: {
      color: "rgb(255, 255, 0)",
      justifyContent: "center",
      fontWeight: 600,
      padding: 0.25,
      [theme.breakpoints.down("xs")]: {
        fontSize: 22,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 22,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 22,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 22,
      },
    },
  })
);

export default function KeyMessage() {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.bottomGrids}
      >
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          xs={12}
          md={12}
          lg={12}
        >
          <Typography variant="h1" className={classes.keyMessage}>
            Fysiotherapie, Manuele therapie & Orofaciale fysiotherapie
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
