import { CssBaseline, Typography, Grid, Avatar } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../../stylesheets/App.css";
import FysiotherapieSpecialisatieFoto from "../../images/fysiotherapie-specialisatie.png";
import Copyright from "../../view/Copyright";
import NavigationBar from "../../components/NavigationBar";
import TopBar from "../../components/TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: "#4b0340",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
    font: {
      padding: 0,
      color: "#3d072d",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "20rem",
        height: "25rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "25rem",
        height: "30rem",
        marginLeft: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30rem",
        height: "30rem",
      },
    },
    boodschap: {
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        marginLeft: "2rem",
        marginRight: "2rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        marginLeft: "4rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginLeft: "6rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        marginLeft: "6rem",
        textAlign: "left",
      },
    },
    imageGrid: {
      alignItems: "center",
      order: 2,
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        marginBottom: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
        marginBottom: "2rem",
      },
    },
    infoGrid: {
      marginTop: "1rem",
      order: 1,
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "2rem",
      },
    },
  })
);

function Fysiotherapie() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important", marginBottom: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BOOST Fysiotherapie / Fysiotherapie</title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio, manuele en orofaciale therapie in Nieuw Vennep. De praktijkeigenaar is Emilie Wegman, Manueel therapeut - OMT Orthopedische Manuele Therapie"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Orofaciale fysiotherapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/specialisaties/fysiotherapie" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          alignItems="flex-start"
          className={classes.infoGrid}
        >
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{
              fontWeight: 700,
              fontSize: 24,
              color: "#4b0340",
              marginBottom: "1rem",
            }}
          >
            Fysiotherapie
          </Typography>

          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            De fysiotherapeut is dé expert in houding en beweging. Hij helpt je
            bij het voorkomen, verhelpen of verminderen van je lichamelijke
            klachten en zorgt ervoor dat je weer optimaal kunt bewegen. De zorg
            van een fysiotherapeut past bij jouw persoonlijke situatie en is
            gericht op het doel dat jij wilt bereiken. Soms krijg je alleen
            advies, soms ook een behandeling. <br />
          </Typography>

          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ fontWeight: 700 }}
          >
            De eerste afspraak
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            Bij de eerste afspraak wil de fysiotherapeut precies weten wat je
            lichamelijke klachten zijn. Zo kan hij vaststellen of je gebaat bent
            bij fysiotherapie of eerst naar je huisarts moet. Zo’n uitgebreid
            gesprek is niet nodig als je door de huisarts of medisch specialist
            bent doorverwezen naar een fysiotherapeut.
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ fontWeight: 700 }}
          >
            Onderzoek
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ marginBottom: "1rem" }}
          >
            Als duidelijk is dat fysiotherapie je kan helpen, ga je samen met je
            fysiotherapeut op zoek naar de mogelijke oorzaken van je klachten.
            Hij wil ook weten wat je persoonlijke doelen zijn. Wat hoop je met
            de behandeling te bereiken? Wat wil je (weer) kunnen? De
            fysiotherapeut onderzoekt ook het lichaamsdeel waar je last van
            hebt, en kijkt welke invloed je klacht of aandoening heeft op je
            houding en de manier waarop je je lichaam beweegt.
          </Typography>

          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
            style={{ fontWeight: 700 }}
          >
            Behandeling
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            Als de fysiotherapeut een diagnose heeft gesteld, bespreekt hij met
            jou het behandelplan. Fysiotherapeutische behandeling past bij jouw
            persoonlijke situatie en is gericht op het doel dat jij wilt
            bereiken.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.imageGrid}
          justifyContent="center"
        >
          <Avatar
            alt="Fysiotherapie Specialisatie"
            src={FysiotherapieSpecialisatieFoto}
            className={classes.avatar}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default Fysiotherapie;
